<template>
  <div>
    <my-header/>
    <div class="table-box">
      <div>
        <span>已提交业务</span>
      </div>
      <el-card class="box-card">
        <div :style="{ fontSize: '16px', color: '#888'}" >
          <span>状态查询:</span>
        <el-select 
          @change="getBusiness"
          :style="{ width: '150px', marginLeft: '20px' }"
          v-model="typevalue"
          placeholder="招募中">
          <el-option
            v-for="item in options"
            :key="item.value" 
            :label="item.label" 
            :value="item.value"
          >
          </el-option>
        </el-select>
        </div>
        <!-- 表结构 -->
        <el-table class="table" 
        stripe :data="businessList" border
        :default-sort="{prop:'updateTime,applyNum',order:'descending'}"
        >
        <el-table-column
          property="title"
          label="项目标题"
          width="240"
          fixed="left"
        ></el-table-column>
        <el-table-column
          property="updateTime"
          label="更新时间"
          width="180"
          sortable
        ></el-table-column>
        <el-table-column
          property="projectContact"
          label="项目联系人"
          width="130"
        ></el-table-column>
        <el-table-column
          property="projectContactPhone"
          label="项目联系人电话"
          width="130"
        ></el-table-column>
        <el-table-column
          property="address"
          label="业务所在地址"
          width="300"
        ></el-table-column>
        <el-table-column property="state" label="状态" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.state | state }}</span>
          </template>
        </el-table-column>
        <el-table-column property="type" label="业务类型" width="85">
          <template slot-scope="scope">
            <span>{{ scope.row.type | type }}</span>
          </template>
          </el-table-column>
          <el-table-column 
          property="applyNum" 
          label="已招募人数"
           width="120"
           sortable>
          </el-table-column>

        <el-table-column label="操作" width="195" fixed="right">
          <template slot-scope="scope">
            <el-button
              class='look'
              type="primary"
              size="small"
              @click="lookBusiness(scope.row)"
              >查看</el-button
            >
            <el-button
              v-if="scope.row.state == 1"
              type="primary"
              size="small"
              @click="reSubmit(scope.row)"
              >重新提交</el-button
            >
             <el-button
              v-if='scope.row.state == 2'
              type="primary"
              size="small"
              @click.stop
              @click="userListactive(scope.row)"
              >报名人员信息</el-button
            >
            
          </template>
        </el-table-column>
      </el-table>
     </el-card> 
      <!-- 弹出显示信息 -->
      <div class="userList" v-show="userListshow" >
      <el-card class="box-card-userList">
        <div>
          <span :style="{ fontSize: '22px', marginLeft: '330px',color: '#888'}" class="el-icon-close" 
           @click="userListshow = false"></span>
          <span v-show="userList.length!=0" :style="{ fontSize: '22px', color: '#888'}">
            <i class="el-icon-share"></i> 报名人员信息:
            <hr>
          </span>
        </div>
        <div class="userList-item" v-for="(i, k) of userList" :key="k">
          <div>
            <span>昵称:</span>
            <span v-text="i.nickName ? i.nickName : '未填写'"></span>
          </div>
          <div>
            <span>地区:</span>
            <span
              v-text="i.region? `${i.region[0]}-${i.region[1]}-${i.region[2]} `: '未填写'"
            ></span>
          </div>
          <div>
            <span>手机:</span>
            <span v-text="i.phone ? i.phone : '未填写'"></span>
          </div>
        </div>
        <div v-show="userList.length==0">
          <span :style="{ fontSize: '16px', color: '#888'}">
            <i class="el-icon-warning-outline"></i>
            <span style="margin-left:5px">当前没有报名人员</span>
            </span>
        </div>
      </el-card>
    </div>

      
    </div>
  </div>
</template>

<script>
import MyHeader from "../components/my-header.vue";
export default {
  data() {
    return {
      item: [],
      userList: [],
      businessList:[],
      userListshow:false,
      typevalue:"",
      options:[
        {
          value:"4",
          label:"全部"
         },
         {
          value:"3",
          label:"已完成"
         },
         {
          value:"2",
          label:"招募中"
         },
         {
          value:"1",
          label:"失败"
         },
         {
          value:"0",
          label:"待审核"
         },
      ]
    };
  },
  filters: {
    type(val) {
      if (val == 0) {
        return "单人";
      } else if (val == 1) {
        return "多人";
      }else{
        return '不详';
      }
    },
    state(val) {
      if (val == 0) {
        return "待审核";
      } else if (val == 1) {
        return "失败";
      } else if (val == 2) {
        return "招募中";
      } else if (val == 3) {
        return "已完成";
      }
    },
    
  },
  methods: {
    getItem() {
      this.item = JSON.parse(this.$route.query.item);
      console.log(this.item,"数据");
      
    },
    
    // 查看详情
    lookBusiness(v) {
      this.$router.push({
        path: "/wallBulletinSubmitDetail",
        query: {
          item: JSON.stringify(v),
        },
      });
    },
    // 重新提交
    reSubmit(v) {
      this.$router.push({
        path: "/setInfoissue",
        query: {
          item: JSON.stringify(v),
        },
      });
    },
    //报名人员详情
    userListactive(v) {
      console.log(v,'------');
      this.userList=v.userShowVo
      this.userListshow =true;

       console.log(this.userList,'人员信息')
       
    },
    //分类获取
    async getBusiness() {
     let typevalue = "";
     //设置默认状态显示
      if (this.typevalue == "" ) {
        typevalue = `&state=2`
      } else if(this.typevalue == 4){
        typevalue = ""
      }else {
        typevalue = `&state=${this.typevalue}`;
      }
      const res = await this.$http({
        methos: "get",
        url: `front/businessInfo/findByUserId?sortType=create_time%20desc${typevalue}`,
      });
      this.listshow=false
      console.log(res.data.data.list,"分类");
      this.businessList=res.data.data.list;
    },
       
  },
  created() {
    this.getItem();
    this.getBusiness()
    
    
  },
  
};
</script>

<style lang="scss" scoped>

.box-card {
  margin: 0 auto;
  margin-bottom: 50px; 
  width: 90%;
  min-width: 1000px;
  
}
.table-box {
  margin-top: 140px;
  font-family: "Arial";
 
  .table{
    width: 100%;
    margin: 10px auto;
    margin-bottom: 50px;
    
  }
  
  & > div:nth-child(1) {
    background: #f5f5f6;
    height: 150px;
    margin: 0 auto;
    font-size: 36px;
    color: #101d37;
    font-weight: 700;
    text-align: center;
    line-height: 120px;
    position: relative;
    color: #101d37 !important;
  }
}
.userList{
  width: 400px;
  height: 400px;
  position:absolute;
  top: 70%;
  left: 50%;
  margin-left: -200px;
  margin-top:-200px;
  
}
.userList-item {
  padding: 5px 5px;
  padding-bottom: 20px;
}
.userList-item:not(:last-child) {
  border-bottom: 1px solid #aaa;
}
.look{
  margin-bottom: 2px;
}
</style>